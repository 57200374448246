








import { Vue, Component } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Page from '@/components/Page.vue';
import CategoryForm, { CategorySubmitEvent } from '@/modules/category/components/CategoryForm.vue';
import { Id } from '../../user/domain/Types';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import { PortalService } from '@/modules/portal/services/PortalService';
import { Category } from '@/modules/category/domain/Category';
import { CategoryService } from '@/modules/category/services/CategoryService';
import { Portal } from '@/modules/portal/domain/Portal';

@Component({
    components: {
        Card,
        Page,
        CategoryForm,
    },
})

export default class ViewUser extends Vue {
    private isLoading: boolean = false;
    private hasError: boolean = false;
    private categoryId: Id = '';
    private action: string = 'create';
    private category: Category | Category = {
        id: '',
        name: '',
        zones: [],
    };
    private portals: Portal[] | Portal[] = [];

    private async mounted() {
        if (this.$route.params.categoryId) {
            this.categoryId = this.$route.params.categoryId;
        }

        await this.fetchCategory();
    }

    private async fetchCategory() {
        this.isLoading = true;
        if (this.categoryId) {
            try {
                this.category = await CategoryService.getById(
                  this.categoryId,
                  this.$store.getters['sessionStore/token'],
                );
            } catch {
                return this.$router.push('/campaign-listing');
            }
        }

        const portals = await PortalService.getAll(this.$store.getters['sessionStore/token']);
        const zones = await PortalService.getZonesForUser(this.$store.getters['sessionStore/token']);
        await portals.forEach((e, index) => {
            portals[index].zones = zones.filter((z: PortalZone) => z.portalId === e.id);
        });
        this.portals = portals;
        this.isLoading = false;
    }

    private async updateCategory(category: CategorySubmitEvent) {
        try {
            this.isLoading = true;
            if (this.categoryId) {
                this.action = 'update';
                await CategoryService.update(this.categoryId, category, this.$store.getters['sessionStore/token'])
                .catch((error) => {
                    throw new Error(error);
                })
                .then(() => {
                    this.$notify.success({ title: 'SUCCÈS', message: 'Les informations ont été mises à jour !' });
                }).finally(() => {
                    this.isLoading = false;
                });
            } else {
                await CategoryService.insert(category, this.$store.getters['sessionStore/token'])
                .catch((error) => {
                    throw new Error(error);
                })
                .then(() => {
                    this.$notify.success({ title: 'SUCCÈS', message: 'Les informations ont été mises à jour !' });
                }).finally(() => {
                    this.isLoading = false;
                    this.categoryId = '';
                });
            }
        } catch (e) {
            this.$notify.error({
                title: 'ERREUR',
                message: e.message,
            });
        }
    }
}
