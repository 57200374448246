


























import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import { UserInterface } from '@/modules/user/domain/UserInterface';
import { PortalZone } from '../../portal/domain/PortalZone';
import PortalZoneTransfer from '@/modules/portal/components/PortalZoneTransfer.vue';
import { Category } from '@/modules/category/domain/Category';
import { Id } from '@/modules/user/domain/Types';
import { Portal } from '@/modules/portal/domain/Portal';

export interface CategorySubmitEvent {
    name: string | null;
    zones: PortalZone[] | [];
}

@Component({
    components: {Field, PortalZoneTransfer},
})
export default class CategoryForm extends Vue {
    @Prop() private portals!: Portal[];
    private selectedZones: PortalZone[] | PortalZone[] = [];
    private isLoadingZones: boolean = false;

    public rules =  {
        name: [
            { required: true, message: 'Le champs nom est requis!', trigger: 'blur' },
        ],
        zones: [
            { required: true, message: 'Le champs zones est requis!', trigger: 'blur' },
        ],
    };

    @Prop({default: {id: '',
            name: '',
            zones: []} }) private readonly category!: Category;

    @Watch('category')
    private onLoadCategory(category: Category) {
        this.setZonesAs(category.zones);
    }

    private submit() {
        const form: any = this.$refs.categoryForm;
        form.validate((valid: boolean) => {
            if (valid) {
                const categorySubmitEvent: CategorySubmitEvent = {
                    name: this.category.name,
                    zones: this.selectedZones,
                };

                this.$emit('submit', categorySubmitEvent);

                if (this.category.id === '') {
                    form.resetFields();
                    const transfer: any = this.$refs.transfer;
                    transfer.$children[0].value.splice(0, transfer.$children[0].value.length);
                }
            } else {
                return false;
            }
        });
    }

    get buttonIcon() {
        return this.category.id !== '' ? 'el-icon-edit' : 'el-icon-add';
    }

    get buttonText() {
        return this.category.id !== '' ? 'Modifier' : 'Ajouter';
    }

    get buttonType() {
        return this.category.id !== '' ? 'warning' : 'success';
    }

    private setZonesAs(zones: PortalZone[]) {
        this.selectedZones = zones;
        this.category.zones = zones;
    }

    private onZonesUpdate(zones: PortalZone[], portalId: string) {
        const filterSelectedZones = this.selectedZones.filter((s) => s.portalId !== portalId);
        this.setZonesAs(filterSelectedZones.concat(zones));
    }
}
